import './NavBar.css';

import { AppContext } from "../AppContext";
import PlayerStore from "../../store/PlayerStore";

import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { observer } from 'mobx-react-lite';
import {useLocation, useNavigate} from "react-router-dom";
import { useModal } from "../../hooks/useModal";

import MyAlert from "../MyAlert/MyAlert";
import RegModalWindow from "../Forms/RegModalWindow/RegModalWindow";
import LogInModalWindow from "../Forms/LogInModalWindow/LogInModalWindow";
import LobbiesCenter from "../LobbiesCenter/LobbiesCenter";
import NotificationCenter from "../NotificationCenter/NotificationCenter";

import { logout } from "../../http/userAPI";

import { BellFill, CollectionFill, List } from "react-bootstrap-icons";
import { ReactComponent as NevaLogo } from './assets/neva_logo.svg';


const Navbar: React.FC<{ loading: boolean }> = observer(({loading}) => {
    const { player, partner } = useContext(AppContext);
    const currentUser = player?.isAuth ? player : partner?.isAuth ? partner : null;

    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const location = useLocation();
    const navigate = useNavigate();

    const { isVisible: showRegModal, openModal: openRegModal, closeModal: closeRegModal } = useModal();
    const { isVisible: showLogInModal, openModal: openLogInModal, closeModal: closeLogInModal } = useModal();
    const { isVisible: showMyAlert, openModal: handleShowMyAlert, closeModal: handleCloseMyAlert } = useModal();

    const [lobbiesVisible, setLobbiesVisible] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [notificationsVisible, setNotificationsVisible] = useState(false);
    const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);

    const lobbiesBtnRef = useRef<HTMLDivElement>(null);
    const bellRef = useRef<HTMLDivElement | null>(null);
    const lobbiesContainerRef = useRef<HTMLDivElement>(null);
    const notificationRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                notificationsVisible &&
                notificationRef.current &&
                !notificationRef.current.contains(event.target as Node) &&
                bellRef.current &&
                !bellRef.current.contains(event.target as Node)
            ) {
                setNotificationsVisible(false);
            }

            if (
                lobbiesVisible &&
                lobbiesContainerRef.current &&
                !lobbiesContainerRef.current.contains(event.target as Node) &&
                lobbiesBtnRef.current &&
                !lobbiesBtnRef.current.contains(event.target as Node)
            ) {
                setLobbiesVisible(false);
            }
        };

        document.addEventListener('click', handleClickOutside, true);

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [notificationsVisible, lobbiesVisible]);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            if (currentScrollY > lastScrollY && currentScrollY > 50) {
                setIsVisible(false);
            } else if (currentScrollY < lastScrollY) {
                setIsVisible(true);
            }

            setLastScrollY(currentScrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    const handleUnreadNotifications = useCallback((hasUnread: boolean) => {
        setHasUnreadNotifications(hasUnread);
    }, []);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleBellClick = () => {
        setNotificationsVisible(prevVisible => !prevVisible);
    };

    const handleLobbiesClick = () => {
        setLobbiesVisible(prevVisible => !prevVisible);
    };

    const handleLogout = () => {
        logout();
        player?.logout();
        partner?.logout();
        navigate('/', { replace: true });
        handleCloseMyAlert();
    };

    return (
        <>
            <nav
                className={`navbar ${isVisible ? 'visible' : 'hidden'}`}
            >
                <div className='navbar_content'>
                    <div className='navbar_contentLinks'>
                        <NevaLogo className='navbar_contentLogo' onClick={() => navigate('/')}/>
                        <a className={`navbar_contentLink ${location.pathname === '/' ? 'currentLink' : ''}`}
                           href='/'>ГЛАВНАЯ</a>
                        <a className={`navbar_contentLink ${location.pathname === '/games' ? 'currentLink' : ''}`}
                           href='/games'>ТУРНИРЫ</a>
                        <a className={`navbar_contentLink ${location.pathname === '/soon' ? 'currentLink' : ''}`}
                           href='/soon'>МАГАЗИН</a>
                    </div>

                    {loading ? (
                        <div></div>
                    ) : (
                        <>
                            {currentUser?.isAuth ? (
                                <>
                                    {currentUser.isAdmin && (
                                        <a
                                            className={`navbar_contentLink ${location.pathname === '/admin-panel' ? 'currentLink' : ''}`}
                                            href='/admin-panel'
                                        >
                                            АДМИНИСТРИРОВАНИЕ
                                        </a>
                                    )}

                                    <div className='navbar_contentUserWrapper'>
                                        <div className='navbar_contentUser_notificationWrapper'>
                                            <div className='navbar_bell_container' ref={bellRef}>
                                                <BellFill size={25} className='navbar_bell' onClick={handleBellClick}/>
                                                {hasUnreadNotifications && <div className='navbar_notif_dot'/>}
                                                <div ref={notificationRef}
                                                     className={`notification_wrapper ${notificationsVisible ? 'visible' : 'hidden'}`}>
                                                    <NotificationCenter
                                                        isVisible={notificationsVisible}
                                                        onUnreadNotificationsChange={handleUnreadNotifications}
                                                    />
                                                </div>
                                            </div>

                                            <div className='navbar_lobbies_container' ref={lobbiesBtnRef}>
                                                <CollectionFill size={25} className='navbar_lobbiesBtn'
                                                                onClick={handleLobbiesClick}/>
                                                <div ref={lobbiesContainerRef}
                                                     className={`notification_wrapper ${lobbiesVisible ? 'visible' : 'hidden'}`}>
                                                    <LobbiesCenter
                                                        isVisible={lobbiesVisible}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='navbar_contentUser'>
                                            <div className='navbar_contentUserAvatarContainer'>
                                                <img
                                                    src={`${process.env.REACT_APP_IMG_STORAGE_URL}${currentUser instanceof PlayerStore ? currentUser.avatarURL : currentUser?.logoUrl}`}
                                                    className="navbar_contentUserAvatar"
                                                    alt={`${currentUser?.login}`}
                                                />
                                            </div>

                                            <div className='navbar_contentUserLoginContainer'>
                                                <a
                                                    className={`navbar_contentUserLogin 
                                                    ${location.pathname === `/player/${currentUser instanceof PlayerStore
                                                        ? currentUser.playerId
                                                        : ''}` ? 'currentLink' : ''}`}
                                                    onClick={() => {
                                                        navigate(currentUser instanceof PlayerStore
                                                            ? `/player/${currentUser.playerId}`
                                                            : `/partner/${currentUser.partnerId}`)
                                                    }
                                                    }
                                                >{currentUser?.login}</a>
                                            </div>

                                            <div className='navbar_contentUserDropdown'>
                                                <List
                                                    className={`navbar_contentUserDropdownIcon ${dropdownVisible ? 'active' : ''}`}
                                                    onClick={toggleDropdown}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className='navbar_contentButtons'>
                                    <button className='navbar_contentButton' onClick={openRegModal}>СОЗДАТЬ АККАУНТ
                                    </button>
                                    <button className='navbar_contentButton login' onClick={openLogInModal}>ВХОД
                                    </button>
                                </div>
                            )}
                        </>
                    )}


                    {(dropdownVisible && currentUser) && (
                        <div className='navbar_dropdown'>
                            <a
                                className="navbar_dropdownLink"
                                href={currentUser instanceof PlayerStore
                                    ? `/player/${currentUser.playerId}`
                                    : `/partner/${currentUser.partnerId}`}
                            >
                                Профиль
                            </a>
                            <a className='navbar_dropdownLink disabled'>Мои друзья</a>
                            <a className='navbar_dropdownLink disabled'>Мои команды</a>
                            <a className='navbar_dropdownLink disabled'>Мои турниры</a>
                            {partner?.partnerId && <a className='navbar_dropdownLink'>Создать турнир</a>}
                            {partner?.partnerId && <a className='navbar_dropdownLink'>Создать новость</a>}
                            <div className='navbar_dropdownLink logOut' onClick={handleShowMyAlert}>Выйти</div>
                        </div>
                    )}
                </div>
            </nav>

            <MyAlert
                onHide={handleCloseMyAlert}
                show={showMyAlert}
                alertText='Вы точно хотите выйти?'
                buttons={[
                    {
                        text: 'Да',
                        onClick: () => handleLogout()
                    },
                    {text: 'Нет', onClick: handleCloseMyAlert}
                ]}
            />
            <RegModalWindow showModal={showRegModal} closeModal={closeRegModal}/>
            <LogInModalWindow showModal={showLogInModal} closeModal={closeLogInModal}/>
        </>
    );
});

export default Navbar;