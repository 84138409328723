import cs2Image from '../../assets/img/gamepic/cs2_gamepic.jpg';
import cs2Baner from '../../assets/img/chooselistgame/cs2.jpg';
import cs2Small from '../../assets/img/gameSmallImg/cs2Small.jpg';
import cs2MainPageImg from '../../assets/img/MainPageGames/mainP_cs2.jpg'

import dota2Image from '../../assets/img/gamepic/dota2_gamepic.jpeg';
import dota2Baner from '../../assets/img/chooselistgame/dota2.jpg';
import dota2Small from '../../assets/img/gameSmallImg/dota2Small.jpg';
import dota2MainPageImg from '../../assets/img/MainPageGames/mainP_dota2.jpg'

import apexImage from '../../assets/img/gamepic/apex_gamepic.jpg';
import apexBaner from '../../assets/img/chooselistgame/apex.jpg';
import apexSmall from '../../assets/img/gameSmallImg/apexSmall.jpg';
import apexMainPageImg from '../../assets/img/MainPageGames/mainP_apex.jpg'

import fortniteImage from '../../assets/img/gamepic/fortnite_gamepic.jpeg';
import fortniteBaner from '../../assets/img/chooselistgame/fortnite.jpg';
import fortniteSmall from '../../assets/img/gameSmallImg/fortniteSmall.jpg';
import fortniteMainPageImg from '../../assets/img/MainPageGames/mainP_fortnite.jpg'

import hearthstoneImage from '../../assets/img/gamepic/hearthstone_gamepic.jpg';
import hearthstoneBaner from '../../assets/img/chooselistgame/heartstone.jpg';
import hearthstoneSmall from '../../assets/img/gameSmallImg/hearthstoneSmall.jpg';
import hearthstoneMainPageImg from '../../assets/img/MainPageGames/mainP_hearthstone.jpg'

import pubgImage from '../../assets/img/gamepic/pubg_gamepic.jpg';
import pubgBaner from '../../assets/img/chooselistgame/pubg.jpg';
import pubgSmall from '../../assets/img/gameSmallImg/pubgSmall.jpg';
import pubgMainPageImg from '../../assets/img/MainPageGames/mainP_pubg.jpg'

import rocketLeagueImage from '../../assets/img/gamepic/rocketLeague_gamepic.jpg';
import rocketLeagueBaner from '../../assets/img/chooselistgame/rocketleg.jpg';
import rocketLeagueSmall from '../../assets/img/gameSmallImg/rocketLeagueSmall.jpg';
import rocketLeagueMainPageImg from '../../assets/img/MainPageGames/mainP_rocketLeague.jpg'

import valorantImage from '../../assets/img/gamepic/valorant_gamepic.jpg';
import valorantBaner from '../../assets/img/chooselistgame/valorant.jpg';
import valorantSmall from '../../assets/img/gameSmallImg/valorantSmall.jpg';
import valorantMainPageImg from '../../assets/img/MainPageGames/mainP_valorant.jpg'

import wotImage from '../../assets/img/gamepic/wotImage_gamepic.jpeg';
import wotBaner from '../../assets/img/chooselistgame/WoT.jpg';
import wotSmall from '../../assets/img/gameSmallImg/wotSmall.jpg';
import wotMainPageImg from '../../assets/img/MainPageGames/mainP_wot.jpg'

import r6sImage from '../../assets/img/gamepic/r6sImage_gamepic.jpeg';
import r6sBaner from '../../assets/img/chooselistgame/r6s.jpg';
import r6sSmall from '../../assets/img/gameSmallImg/r6eSmall.jpg';
import r6sMainPageImg from '../../assets/img/MainPageGames/mainP_r6.jpg'

import theFainalsImage from '../../assets/img/gamepic/theFainals_gamepic.jpg';
import theFainalsBaner from '../../assets/img/chooselistgame/theFainals.jpg';
import theFainalsSmall from '../../assets/img/gameSmallImg/theFainalsSmall.jpg';
import theFainalsMainPageImg from '../../assets/img/MainPageGames/mainP_theFinals.jpg'

const  TournamentConstants = class {
    public static CS2 = class {
        static gameName: string = "CS 2"
        static gameTypeId: string = "f9c18834-95f0-49ee-9de9-6116bcd08161"
        static gameImage: string = cs2Image;
        static gameBaner: string = cs2Baner;
        static gameSmallImage: string = cs2Small;
        static gameIcon: string = `${process.env.REACT_APP_IMG_STORAGE_URL}cs2_small_icon.jpg`;
        static gameGenre: string = 'ТАКТИЧЕСКИЙ ШУТЕР';
        static gameSteamLink: string = 'https://store.steampowered.com/app/730/CounterStrike_2/';
        static gameMainPageImg: string = cs2MainPageImg;
    }
    
    public static DOTA2 = class {
        static gameName: string = "DOTA 2";
        static gameTypeId: string = "f607bdfa-5731-4939-b046-c2917f3ad962";
        static gameImage: string = dota2Image;
        static gameBaner: string = dota2Baner;
        static gameSmallImage: string = dota2Small;
        static gameIcon: string = `${process.env.REACT_APP_IMG_STORAGE_URL}dota2_small_icon.png`;
        static gameGenre: string = 'МОБА';
        static gameSteamLink: string = 'https://store.steampowered.com/app/570/Dota_2/';
        static gameMainPageImg: string = dota2MainPageImg;
    }
    
    public static APEX = class {
        static gameName: string = "APEX Legends";
        static gameTypeId: string = "";
        static gameImage: string = apexImage;
        static gameBaner: string = apexBaner;
        static gameSmallImage: string = apexSmall;
        static gameGenre: string = 'КОРОЛЕВСКАЯ БИТВА';
        static gameSteamLink: string = 'https://store.steampowered.com/app/1172470/Apex_Legends/';
        static gameMainPageImg: string = apexMainPageImg;
    }
    
    public static FORTNITE = class {
        static gameName: string = "FORTNITE";
        static gameTypeId: string = "";
        static gameImage: string = fortniteImage;
        static gameBaner: string = fortniteBaner;
        static gameSmallImage: string = fortniteSmall;
        static gameGenre: string = 'КОРОЛЕВСКАЯ БИТВА';
        static gameSteamLink: string = '';
        static gameMainPageImg: string = fortniteMainPageImg;
    }
    
    public static HEARTHSTONE = class {
        static gameName: string = "HEARTHSTONE";
        static gameTypeId: string = "";
        static gameImage: string = hearthstoneImage;
        static gameBaner: string = hearthstoneBaner;
        static gameSmallImage: string = hearthstoneSmall;
        static gameGenre: string = 'Cтратегическая игра';
        static gameSteamLink: string = '';
        static gameMainPageImg: string = hearthstoneMainPageImg;
    }
    
    public static PUBG = class {
        static gameName: string = "PUBG";
        static gameTypeId: string = "";
        static gameImage: string = pubgImage;
        static gameBaner: string = pubgBaner;
        static gameSmallImage: string = pubgSmall;
        static gameGenre: string = 'КОРОЛЕВСКАЯ БИТВА';
        static gameSteamLink: string = 'https://store.steampowered.com/app/578080/PUBG_BATTLEGROUNDS/';
        static gameMainPageImg: string = pubgMainPageImg;
    }
    
    public static ROCKETLIAGUE = class {
        static gameName: string = "Rocket League";
        static gameTypeId: string = "";
        static gameImage: string = rocketLeagueImage;
        static gameBaner: string = rocketLeagueBaner;
        static gameSmallImage: string = rocketLeagueSmall;
        static gameGenre: string = 'Спортивный симулятор';
        static gameSteamLink: string = '';
        static gameMainPageImg: string = rocketLeagueMainPageImg;
    }
    
    public static VALORANT = class {
        static gameName: string = "VALORANT";
        static gameTypeId: string = "";
        static gameImage: string = valorantImage;
        static gameBaner: string = valorantBaner;
        static gameSmallImage: string = valorantSmall;
        static gameGenre: string = 'Тактический шутер';
        static gameSteamLink: string = '';
        static gameMainPageImg: string = valorantMainPageImg;
    }
    
    public static THEFAINALS = class {
        static gameName: string = "THE FINALS";
        static gameTypeId: string = "";
        static gameImage: string = theFainalsImage;
        static gameBaner: string = theFainalsBaner;
        static gameSmallImage: string = theFainalsSmall;
        static gameGenre: string = 'Тактический шутер';
        static gameSteamLink: string = 'https://store.steampowered.com/app/2073850/THE_FINALS/';
        static gameMainPageImg: string = theFainalsMainPageImg;
    }
    
    public static WORLDOFTANKS = class {
        static gameName: string = "World of Tanks";
        static gameTypeId: string = "";
        static gameImage: string = wotImage;
        static gameBaner: string = wotBaner;
        static gameSmallImage: string = wotSmall;
        static gameGenre: string = 'Симулятор';
        static gameSteamLink: string = '';
        static gameMainPageImg: string = wotMainPageImg;
    }
    
    public static RAINBOWSIXSIEGE = class {
        static gameName: string = "Rainbow Six Siege";
        static gameTypeId: string = "";
        static gameImage: string = r6sImage;
        static gameBaner: string = r6sBaner;
        static gameSmallImage: string = r6sSmall;
        static gameGenre: string = 'Тактический шутер';
        static gameSteamLink: string = '';
        static gameMainPageImg: string = r6sMainPageImg;
    }
    
    public static Games = [
        {
            gameName: this.CS2.gameName,
            gameTypeId: this.CS2.gameTypeId,
            gameImage: this.CS2.gameImage,
            gameBaner: this.CS2.gameBaner,
            gameSmallImage: this.CS2.gameSmallImage,
            gameGenre: this.CS2.gameGenre,
            gameSteamLink: this.CS2.gameSteamLink,
            gameMainPageImg: this.CS2.gameMainPageImg,
        },
        {
            gameName: this.DOTA2.gameName,
            gameTypeId: this.DOTA2.gameTypeId,
            gameImage: this.DOTA2.gameImage,
            gameBaner: this.DOTA2.gameBaner,
            gameSmallImage: this.DOTA2.gameSmallImage,
            gameGenre: this.DOTA2.gameGenre,
            gameSteamLink: this.DOTA2.gameSteamLink,
            gameMainPageImg: this.DOTA2.gameMainPageImg,
        },
        {
            gameName: this.APEX.gameName,
            gameTypeId: this.APEX.gameTypeId,
            gameImage: this.APEX.gameImage,
            gameBaner: this.APEX.gameBaner,
            gameSmallImage: this.APEX.gameSmallImage,
            gameGenre: this.APEX.gameGenre,
            gameSteamLink: this.APEX.gameSteamLink,
            gameMainPageImg: this.APEX.gameMainPageImg,
        },
        {
            gameName: this.FORTNITE.gameName,
            gameTypeId: this.FORTNITE.gameTypeId,
            gameImage: this.FORTNITE.gameImage,
            gameBaner: this.FORTNITE.gameBaner,
            gameSmallImage: this.FORTNITE.gameSmallImage,
            gameGenre: this.FORTNITE.gameGenre,
            gameSteamLink: this.FORTNITE.gameSteamLink,
            gameMainPageImg: this.FORTNITE.gameMainPageImg,
        },
        {
            gameName: this.HEARTHSTONE.gameName,
            gameTypeId: this.HEARTHSTONE.gameTypeId,
            gameImage: this.HEARTHSTONE.gameImage,
            gameBaner: this.HEARTHSTONE.gameBaner,
            gameSmallImage: this.HEARTHSTONE.gameSmallImage,
            gameGenre: this.HEARTHSTONE.gameGenre,
            gameSteamLink: this.HEARTHSTONE.gameSteamLink,
            gameMainPageImg: this.HEARTHSTONE.gameMainPageImg,
        },
        {
            gameName: this.PUBG.gameName,
            gameTypeId: this.PUBG.gameTypeId,
            gameImage: this.PUBG.gameImage,
            gameBaner: this.PUBG.gameBaner,
            gameSmallImage: this.PUBG.gameSmallImage,
            gameGenre: this.PUBG.gameGenre,
            gameSteamLink: this.PUBG.gameSteamLink,
            gameMainPageImg: this.PUBG.gameMainPageImg,
        },
        {
            gameName: this.ROCKETLIAGUE.gameName,
            gameTypeId: this.ROCKETLIAGUE.gameTypeId,
            gameImage: this.ROCKETLIAGUE.gameImage,
            gameBaner: this.ROCKETLIAGUE.gameBaner,
            gameSmallImage: this.ROCKETLIAGUE.gameSmallImage,
            gameGenre: this.ROCKETLIAGUE.gameGenre,
            gameSteamLink: this.ROCKETLIAGUE.gameSteamLink,
            gameMainPageImg: this.ROCKETLIAGUE.gameMainPageImg,
        },
        {
            gameName: this.VALORANT.gameName,
            gameTypeId: this.VALORANT.gameTypeId,
            gameImage: this.VALORANT.gameImage,
            gameBaner: this.VALORANT.gameBaner,
            gameSmallImage: this.VALORANT.gameSmallImage,
            gameGenre: this.VALORANT.gameGenre,
            gameSteamLink: this.VALORANT.gameSteamLink,
            gameMainPageImg: this.VALORANT.gameMainPageImg,
        },
        {
            gameName: this.THEFAINALS.gameName,
            gameTypeId: this.THEFAINALS.gameTypeId,
            gameImage: this.THEFAINALS.gameImage,
            gameBaner: this.THEFAINALS.gameBaner,
            gameSmallImage: this.THEFAINALS.gameSmallImage,
            gameGenre: this.THEFAINALS.gameGenre,
            gameSteamLink: this.THEFAINALS.gameSteamLink,
            gameMainPageImg: this.THEFAINALS.gameMainPageImg,
        },
        {
            gameName: this.WORLDOFTANKS.gameName,
            gameTypeId: this.WORLDOFTANKS.gameTypeId,
            gameImage: this.WORLDOFTANKS.gameImage,
            gameBaner: this.WORLDOFTANKS.gameBaner,
            gameSmallImage: this.WORLDOFTANKS.gameSmallImage,
            gameGenre: this.WORLDOFTANKS.gameGenre,
            gameSteamLink: this.WORLDOFTANKS.gameSteamLink,
            gameMainPageImg: this.WORLDOFTANKS.gameMainPageImg,
        },
        {
            gameName: this.RAINBOWSIXSIEGE.gameName,
            gameTypeId: this.RAINBOWSIXSIEGE.gameTypeId,
            gameImage: this.RAINBOWSIXSIEGE.gameImage,
            gameBaner: this.RAINBOWSIXSIEGE.gameBaner,
            gameSmallImage: this.RAINBOWSIXSIEGE.gameSmallImage,
            gameGenre: this.RAINBOWSIXSIEGE.gameGenre,
            gameSteamLink: this.RAINBOWSIXSIEGE.gameSteamLink,
            gameMainPageImg: this.RAINBOWSIXSIEGE.gameMainPageImg,
        },
    ];    
}

export { TournamentConstants };