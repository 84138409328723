import './Footer.css';

import nevaLogo from './assets/neva_logo.svg';

import { ReactComponent as TgIcon } from './assets/icons/footerTgIcon.svg';
import { ReactComponent as GlobeIcon } from './assets/icons/footerGlobeIcon.svg';
import { ReactComponent as VkIcon } from './assets/icons/footerVkIcon.svg';
import { ReactComponent as DiscordIcon } from './assets/icons/footerDiscordIcon.svg';

const Footer = () => {
    return (
        <footer className='footerContainer'>
            <div className='footerContent'>
                <img src={nevaLogo} alt='NEVA' />

                <div className='footerContentLinks'>
                    <div className='footerLinks first'>
                        <h5>Компания</h5>
                        <a href="https://greenspacedev.ru/" target="_blank" rel="noopener noreferrer">О нас</a>
                        <a>Служба поддержки</a>
                    </div>

                    <div className='footerLinks first'>
                        <h5>Полезные ссылки</h5>
                        <a>Пользовательское соглашение</a>
                        <a>Политика конфиденциальности</a>
                    </div>
                </div>
            </div>

            <hr></hr>

            <div className='footerContent second'>
                <p>ООО "ГринСпэйс"</p>

                <div className='footerContent_mediaIcons'>
                    <a href="https://greenspacedev.ru/" target="_blank" rel="noopener noreferrer">
                        <GlobeIcon className='footerContent_mediaIcon'/>
                    </a>

                    <a href="https://t.me/greenspacegg" target="_blank" rel="noopener noreferrer">
                        <TgIcon className='footerContent_mediaIcon'/>
                    </a>

                    <a href="https://vk.com/greenspacegg" target="_blank" rel="noopener noreferrer">
                        <VkIcon className='footerContent_mediaIcon'/>
                    </a>

                    <a href="https://vk.com/away.php?to=https%3A%2F%2Fdiscord.gg%2FQrKP28JTJn" target="_blank" rel="noopener noreferrer">
                        <DiscordIcon className='footerContent_mediaIcon'/>
                    </a>
                </div>
            </div>
        </footer>
)
}

export default Footer;