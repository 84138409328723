import React, { useState, useEffect } from 'react';

import './ServersMap.css';

import { ReactComponent as ServersMapSvg } from './assets/serversMap.svg';
import { ReactComponent as ServerStatusIcon } from './assets/serverStatusIcon.svg';

const cityNames: Record<string, string> = {
      spb: 'Санкт-Петербург',
      perm: 'Пермь',
      krasnoyarsk: 'Красноярск',
      yakutsk: 'Якутск',
      khabarovsk: 'Хабаровск',
};

export const ServersMap = () => {
      const [tooltip, setTooltip] = useState({
            visible: false,
            text: '',
            x: 0,
            y: 0,
            isDisabled: false,
      });

      useEffect(() => {
            const circles = document.querySelectorAll('circle.server');

            const handleMouseEnter = (evt: Event) => {
                  const e = evt as MouseEvent;
                  const circle = e.target as SVGCircleElement;
                  const circleId = circle.id;

                  const path = document.querySelector(`path#${CSS.escape(circleId)}`);
                  if (path) {
                        path.setAttribute('stroke', '#45FFA6');
                        path.setAttribute('fill', '#45FFA6');
                  }

                  const currentRadius = parseFloat(circle.getAttribute('r') || '3');
                  circle.setAttribute('r', (currentRadius * 1.4).toString());

                  const isDisabled = circle.classList.contains('disabled');

                  const cityName = cityNames[circleId] || 'Неизвестный город';
                  const tooltipText = isDisabled ? 'Скоро...' : cityName;

                  const rect = circle.getBoundingClientRect();
                  const centerX = rect.left + rect.width / 2;
                  const centerY = rect.top + rect.height / 2;

                  setTooltip({
                        visible: true,
                        text: tooltipText,
                        x: centerX,
                        y: centerY,
                        isDisabled,
                  });
            };

            // При уходе
            const handleMouseLeave = (evt: Event) => {
                  const e = evt as MouseEvent;
                  const circle = e.target as SVGCircleElement;
                  const circleId = circle.id;

                  const path = document.querySelector(`path#${CSS.escape(circleId)}`);
                  if (path) {
                        path.setAttribute('stroke', '#121212');
                        path.setAttribute('fill', 'black');
                  }

                  const currentRadius = parseFloat(circle.getAttribute('r') || '3');
                  circle.setAttribute('r', (currentRadius / 1.4).toString());

                  setTooltip((prev) => ({
                        ...prev,
                        visible: false,
                  }));
            };

            circles.forEach((circle) => {
                  circle.addEventListener('mouseenter', handleMouseEnter as EventListener);
                  circle.addEventListener('mouseleave', handleMouseLeave as EventListener);
            });

            return () => {
                  circles.forEach((circle) => {
                        circle.removeEventListener('mouseenter', handleMouseEnter as EventListener);
                        circle.removeEventListener('mouseleave', handleMouseLeave as EventListener);
                  });
            };
      }, []);

      return (
          <div className="neva_serversMap_container">
                <div className="neva_serverMap_bg">
                      <div className="neva_serverMap_circle one" />
                      <div className="neva_serverMap_circle two" />
                      <div className="neva_serverMap_circle free" />
                </div>

                <div className="neva_serversMap">
                      <div className="neva_serverMap_content">
                            <h3 className="neva_serverMap_contentTitle">
                                  Играй на равных,<br />где бы ты ни был
                            </h3>
                            <p className="neva_serverMap_contentDesc">
                                  Стабильное соединение — основа честной игры. Наши сервера<br/>уже работают
                                  в ключевых городах России, и мы продолжаем расширяться,<br/>чтобы обеспечить
                                  комфортную игру каждому пользователю.<br/>Присоединяйся сегодня и стань частью
                                  развивающейся киберспортивной<br/>сети!
                            </p>
                      </div>

                      <ServersMapSvg />

                      {tooltip.visible && (
                          <div
                              className="neva_serversMap_tooltip"
                              style={{
                                    position: 'fixed',
                                    top: tooltip.y - 55,
                                    left: tooltip.x - 25,
                              }}
                          >
                                {tooltip.text}

                                <ServerStatusIcon className={`neva_serversMap_serverStatusIcon ${tooltip.isDisabled ? '' : 'active'}`} />
                          </div>
                      )}
                </div>
          </div>
      );
};

export default ServersMap;
